<template>
    <el-dialog class="video-view-dialog" :title="title || ''" :visible="onlinePreviewVisible" :append-to-body="true"
        :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="60%" top="5vh">
        <el-row>
            <el-col :span="24">
                <video class="video-view video" v-if="type == 'video'" :src="url" controls></video>
                <audio class="video-view" v-if="type == 'audio'" :src="url" controls></audio>
                <el-image v-if="type == 'image'" class="video-view" :src="url" :preview-src-list="[url]">
                </el-image>
                <div v-if="type == 'pdf'">
                    <!-- <pdf
            v-for="i in numPages"
            :key="i"
            :src="pdfSrc"
            :page="i"
            style="display: inline-block; width: 25%"
          ></pdf> -->
                    <!-- <pdf
            ref="myPdfComponent"
            :src="url"
          ></pdf> -->
                    <!-- ref="pdf"
               -->
                    <div>
                        <iframe :src="url" frameborder="0" class="ingroe-iframe"></iframe>
                    </div>
                    <!-- <pdf :src="pdfUrl" v-for="i in num" :key="i" :page="i" /> -->
                </div>
            </el-col>
        </el-row>
        <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </div> -->
    </el-dialog>
</template>
<script>
    import pdf from "vue-pdf";
    export default {
        name: "videoViewDialog",
        components: {
            pdf,
        },
        props: {
            onlinePreviewVisible: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: "",
            },
            url: {
                type: String,
                default: "",
            },
            title: {
                type: String,
                default: "",
            },
        },
        watch: {
            videoViewDialogVisible() {
                // console.log(this.videoViewDialogVisible);
                this.visible = this.onlinePreviewVisible;
            },
        },
        data() {
            return {
                visible: false,
                pdfUrl: null,
                num: 0,
                numPages: 0,
                loading: true,
            };
        },
        methods: {
            //视频预览弹窗关闭
            handleClose() {
                this.$emit("closeOnlinePreviewDialog", false);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .video-view {
        width: 100%;
        background: #000;

        &.video {
            height: 60vh;
        }
    }

    .ingroe-iframe {
        width: 100%;
        height: 700px;
    }
</style>
